<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="pa-0 py-3">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.quertEdit") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
                <v-col class="py-0" cols="12">
                    <v-select
                        :items="category"
                        :label="$vuetify.lang.t('$vuetify.classify')" 
                        :rules="generalRule" 
                        v-model="classify"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-select
                        :items="category"
                        :label="$vuetify.lang.t('$vuetify.level')" 
                        :rules="generalRule" 
                        v-model="priority"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.tel')" 
                        :rules="generalRule" 
                        v-model="tel"
                    />
                </v-col>
                <v-col class="py-0">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.email')" 
                        :rules="generalRule" 
                        v-model="email"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.title')" 
                        :rules="generalRule" 
                        v-model="title"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-textarea
                        :label="$vuetify.lang.t('$vuetify.des')"
                        outlined
                        v-model="problem"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-file-input
                        :label="$vuetify.lang.t('$vuetify.annex')"
                        prepend-icon=""
                        append-icon="mdi-file"
                        v-model="file"
                    />
                </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuestionCRUD",
  props: { dialog: Boolean },
  data() {
    return {
      valid: false,
      generalRule: [v => !!v || "this field is required"],
      category: [],
      classify: [],
      priority: [],
      tel: '',
      email: '',
      title: '',
      file: [],
      problem: '',
      myObj: {},
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate() == true ? this.$emit('handleData', this.myObj) : console.log("false");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    }
  }
};
</script>

<style></style>
